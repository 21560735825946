import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
//

import Home from './../pages/Home';
// import Example from "./../pages/Example";
import Catalog from '../pages/Catalog';
import Search from '../pages/Search';
import Detail from '../pages/Detail';

// ----------------------------------------------------------------------

export default createBrowserRouter([
  // Home Routes
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'catalog',
        element: <Catalog />,
      },
      {
        path: 'search',
        element: <Search />,
      },
      {
        path: 'detail',
        element: <Detail />,
      },
      {
        path: 'detail/:id',
        element: <Detail />,
      },
    ],
  },

  { path: '*', element: <Navigate to='/' replace /> },
]);
