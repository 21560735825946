import React, { useContext } from 'react';
import { TextField, InputAdornment, Typography, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import bluredLogo from '../assets/bluredLogo.png';
import Search from '@mui/icons-material/Search';
import { SearchContext } from '../context/SearchContext';

export default function CatalogHero() {
  const navigate = useNavigate();
  const context = useContext(SearchContext);

  if (!context) {
    return null;
  }

  const { setSearch } = context;
  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    const fieldValue = (event.currentTarget as any)['outlined-basic'].value;
    setSearch(fieldValue);
    navigate('/search');
  };

  return (
    <>
      <img
        alt=""
        src={bluredLogo}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column' as const,
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Stack sx={{ marginTop: '72px' }}>
          <Typography
            sx={{
              color: 'var(--neutral-800, #232326)',
              textAlign: 'center',
              fontSize: '48px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '72px',
              letterSpacing: '0.768px',
              marginBottom: '4px',
            }}
          >
            Access Suite of Services
          </Typography>
          <Typography
            sx={{
              color: 'var(--neutral-500, #696972)',
              textAlign: 'center',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              width: '687px',
              lineHeight: '24px',
              letterSpacing: '0.288px',
              marginTop: '4px',
              marginBottom: '20px',
            }}
          >
            Our services network provides easy access to the solutions you need, when you need them, regardless of rand,
            rail or payment type
          </Typography>
          <form onSubmit={handleSearch}>
            <TextField
              id="outlined-basic"
              label="Search by API name, language, etc..."
              variant="outlined"
              sx={{
                width: '687px',
                marginTop: '20px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Stack>
      </Box>
    </>
  );
}
