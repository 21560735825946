import React from 'react';

import Page from '../components/Page';
import Hero from '../components/CatalogHero';
import Header from '../components/Header';
import CardShow from '../components/CardShow';
import CardList from '../components/CardList';
import TabCatalog from '../components/TabCatalog';
import cardInfo from '../data/cardInfo.json';
import Pagination from '@mui/material/Pagination';
import Footer from '../components/Footer';

export default function LandingPage() {
  return (
    <Page>
      <Header />
      <Hero />
      <CardShow cards={cardInfo} />
      <TabCatalog />
      <CardList cards={cardInfo} />
      <Pagination
        count={10}
        shape="rounded"
        sx={{
          color: 'var(--neutral-600, #525259)',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '24px',
          letterSpacing: '0.24px',
          justifyContent: 'center',
          display: 'flex',
          marginBottom: '72px',
        }}
      />
      <Footer />
    </Page>
  );
}
