import React from 'react';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Stack,
  Divider,
  Button,
  ListItemIcon,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ModalDetail from './ModalDetail';
import bluredLogo from '../assets/bluredLogo.png';
import Icon from '../assets/iconCard.svg';
import Logo from '../assets/logoMaster.svg';
import ListIcon from '@mui/icons-material/FormatListNumbered';
import Header from './Header';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabDetail() {
  const navigate = useNavigate();

  const Back = () => {
    navigate(-1);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          margin: '0 136px 40px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <img
            alt=''
            src={bluredLogo}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              margin: '0 auto',
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              zIndex: -1,
            }}
          />
          <Box sx={{ display: 'flex' }}>
            <Button
              variant='text'
              sx={{
                alignItems: 'start',
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: 'Mark pro',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={Back}
            >
              ← Back
            </Button>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
          >
            <Stack sx={{ width: '24px', height: '24px', marginRight: '16px' }}>
              <img src={Icon} alt='icon' />
            </Stack>
            <Typography
              variant='h4'
              component='div'
              fontWeight='bold'
              sx={{
                fontSize: '32px',
                lineHeight: '48px',
                letterSpacing: '0.512px',
              }}
            >
              Digital Solutions
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '40px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Stack sx={{ width: '40px' }}>
                <img src={Logo} alt='logo' />
              </Stack>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ fontWeight: 'medium' }}
              >
                Developed by
              </Typography>
              <Typography
                variant='body2'
                color='text.primary'
                sx={{ marginRight: '32px', fontWeight: 'bold' }}
              >
                Mastercard®
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <ListItemIcon sx={{ marginRight: '-32px' }}>
                  <ListIcon />
                </ListItemIcon>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ fontWeight: 'bold' }}
                >
                  Version
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ fontWeight: '800' }}
                >
                  3.12
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              textColor='primary'
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <Tab
                label='Overview'
                sx={{
                  color: 'var(--third-400, #FF5F00)',
                  backgroundColor:
                    value === 0 ? 'var(--third-50, #FFDFCC)' : 'transparent',
                  borderRadius: '100px',
                  fontWeight: 'bold',
                  marginRight: '32px',
                  textTransform: 'none',
                  fontSize: '15px',
                }}
                {...a11yProps(0)}
              />
              <Tab
                label='Documentation'
                sx={{
                  color: 'var(--third-400, #FF5F00)',
                  backgroundColor:
                    value === 1 ? 'var(--third-50, #FFDFCC)' : 'transparent',
                  borderRadius: '100px',
                  fontWeight: 'bold',
                  marginRight: '32px',
                  textTransform: 'none',
                  fontSize: '15px',
                }}
                {...a11yProps(1)}
              />
              <Tab
                label='Support'
                sx={{
                  color: 'var(--third-400, #FF5F00)',
                  backgroundColor:
                    value === 2 ? 'var(--third-50, #FFDFCC)' : 'transparent',
                  borderRadius: '100px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  fontSize: '15px',
                }}
                {...a11yProps(2)}
              />
            </Tabs>
            <Box>
              <ModalDetail />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              marginTop: '40px',
              marginRight: '32px',
              width: '80%',
            }}
          >
            <Stack>
              <Typography
                variant='h4'
                sx={{
                  color: 'var(--neutral-800, #232326)',
                  textAlign: 'start',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '48px',
                  marginBottom: '16px',
                }}
              >
                Description
              </Typography>
              <Box
                color='text.secondary'
                sx={{
                  textAlign: 'start',
                  fontSize: '16px',
                  fontWeight: 400,
                  letterSpacing: '0.256px',
                  width: '800px',
                }}
              >
                <Typography sx={{ marginBottom: '20px' }}>
                  Mastercard is enabling you to optimize and unlock digital
                  commerce everywhere, giving consumers the security,
                  convenience and control they demand every way they want to
                  pay.
                </Typography>
                <Typography sx={{ marginBottom: '20px' }}>
                  The Digital Solutions offered via Mastercard Access allows
                  issuers to manage tokenization and digitization across a range
                  of methods to enable simple,secure and scalable digital
                  payment experiences.
                </Typography>
                <Typography>
                  We manage tokenization and digitization processes for issuers,
                  ensuring cardholder payment information is secure as it passes
                  between entities.
                </Typography>
              </Box>
            </Stack>
            <Stack>
              <Typography
                color='var(--neutral-500, #696972)'
                sx={{ fontWeight: '700', marginBottom: '16px' }}
              >
                CATEGORIES
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginBottom: '16px',
                }}
              >
                <Stack sx={{ width: '40px', marginRight: '8px' }}>
                  <img src={Logo} alt='logo' />
                </Stack>
                <Button
                  variant='contained'
                  sx={{
                    background: 'var(--neutral-700, #3B3B3F)',
                    pointerEvents: 'none',
                    fontSize: '12px',
                    fontFamily: 'Mark pro',
                    fontWeight: 'bold',
                  }}
                >
                  ISSUER
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TabDetail;
