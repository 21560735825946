import React from 'react';
import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')({
    backgroundColor: '#3B3B3F',
    marginRight: '8px',
    padding: '4px 16px',
    borderRadius: '4px',
    color: 'var(--White, #FFF)',
  
    /* Caption/Small */
    fontFamily: 'Mark Pro',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px', /* 160% */
    letterSpacing: '0.16px',
    textTransform: 'uppercase',
  });

function CustomStyledSpan({ children, type }: { children: React.ReactNode, type: string }) {
  return <StyledSpan style={{
    backgroundColor: type === 'primary' ? '#3B3B3F' : '#FFFFFF',
  }}>{children}</StyledSpan>;
}

export default CustomStyledSpan;
