import React from 'react';
import { Box, Card, CardContent, Typography, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Icon from '../assets/iconCard.svg';
import Logo from '../assets/logoMaster.svg';
import ModalCard from './ModalCard';
import Tag from './Tag';

interface TagData {
  text: string;
  backgroundColor: string;
}

interface CardData {
  title: string;
  content: string;
  tags: TagData[];
}

interface CardListProps {
  cards: CardData[];
}

const CardShow: React.FC<CardListProps> = ({ cards }) => {
  return (
    <Stack>
      <Typography
        style={{
          color: 'var(--neutral-800, #232326)',
          textAlign: 'center',
          fontSize: '36px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '48px',
          letterSpacing: '0.576px',
          marginBottom: '4px',
          marginTop: '88px',
        }}
      >
        Featured Solutions
      </Typography>
      <Typography
        style={{
          color: 'var(--neutral-500, #696972)',
          textAlign: 'center',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 400,
          width: '687px',
          lineHeight: '24px',
          letterSpacing: '0.288px',
          marginTop: '4px',
          marginBottom: '20px',
          alignSelf: 'center',
        }}
      >
        Our top picks to start using Mastercard® Access
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
          padding: '24px',
          gap: '16px',
        }}
      >
        {cards.slice(0, 3).map((card, index) => (
          <Card
            key={index}
            sx={{
              flexBasis: 'calc(33.33% - 32px)',
              marginBottom: '32px',
              border: '1px solid var(--neutral-100, #CDCDD0);',
              borderRadius: '8px',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <RouterLink to={'/detail'} style={{ textDecoration: 'none' }}>
                  <Stack
                    sx={{
                      width: '40px',
                      height: '40px',
                      marginRight: '8px',
                      marginBottom: '16px',
                    }}
                  >
                    <img src={Icon} alt="icon" />
                  </Stack>
                </RouterLink>
                <ModalCard />
              </Box>
              <RouterLink to={'/detail'} style={{ textDecoration: 'none' }}>
                <Typography
                  variant="h5"
                  component="div"
                  fontWeight="bold"
                  sx={{
                    marginBottom: '16px',
                    color: 'var(--neutral-800, #232326)',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '32px',
                    letterSpacing: '0.32px',
                  }}
                >
                  {card.title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginBottom: '16px',
                  }}
                >
                  <Stack sx={{ width: '40px', marginRight: '8px' }}>
                    <img src={Logo} alt="logo" />
                  </Stack>
                  {card.tags.map((tag, tagIndex) => (
                    <Tag key={tagIndex} type="primary">
                      {tag.text}
                    </Tag>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px',
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: 'var(--neutral-400, #81818B)',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.208px',
                    }}
                  >
                    Developed by:
                  </Typography>
                  <Stack sx={{ width: '40px' }}>
                    <img src={Logo} alt="logo" />
                  </Stack>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    sx={{
                      color: 'var(--neutral-600, #525259)',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '24px',
                      letterSpacing: '0.208px',
                    }}
                  >
                    MasterCard®
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: 'var(--neutral-400, #81818B)',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.224px',
                    }}
                  >
                    {card.content}
                  </Typography>
                </Box>
              </RouterLink>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Stack>
  );
};

export default CardShow;
