import React from 'react';

import {
  Box,
  Typography,
  Button,
  Stack,
  FormControl,
  Select,
  Grid,
  MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from '../assets/Icon.svg';
import SupportIcon from '../assets/supportIcon.svg';
import LanguageIcon from '@mui/icons-material/Language';

const buttonStyle = {
  height: 0,
  pointerEvents: 'none',
  textTransform: 'none',
};

export default function Footer() {
  return (
    <Box>
      <Box
        sx={{
          height: '200px',
          backgroundColor: 'var(--neutral-50, #E6E6E8)',
          paddingLeft: '64px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{ marginRight: '40px' }}>
          <Typography
            color='var(--neutral-500, #696972)'
            sx={{ fontWeight: 'bold', fontSize: '32px' }}
          >
            Have a question?
          </Typography>
          <Typography
            color='var(--neutral-700, #3B3B3F)'
            sx={{ marginBottom: '24px' }}
          >
            We're here to help
          </Typography>
          <Button
            variant='contained'
            sx={{
              background: 'var(--neutral-800, #232326)',
              fontFamily: 'Mark Pro',
              fontWeight: 'bold',
              fontSize: '14px',
              width: '106px',
              height: '40px',
              textTransform: 'none',
            }}
          >
            Contact
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack height={'75px'}>
            <img src={SupportIcon} alt='support' />
          </Stack>
          <Box ml={2}>
            <Typography
              color='var(--primary-400, #EB001B)'
              sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '8px' }}
            >
              Contact Support
            </Typography>
            <Typography sx={{ fontSize: '14px', maxWidth: '300px' }}>
              If you need to get immediate help, please contact your Mastercard
              representative
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          height: '56px',
          backgroundColor: 'var(--neutral-700, #3B3B3F)',
          paddingLeft: '64px',
          paddingRight: '64px',
        }}
      >
        <Stack
          direction={'row'}
          spacing={'12px'}
          sx={{ paddingTop: '16px', height: '50px' }}
        >
          <img
            src={Icon}
            alt=''
            style={{ width: '40px', marginRight: '24px', height: '25px' }}
          />
          <Typography
            color='var(--neutral-400, #81818B)'
            sx={{ paddingRight: '40px', fontWeight: 'medium' }}
          >
            © 1994-2023 Mastercard
          </Typography>
          <Link to='/' style={{ marginRight: '40px' }}>
            <Button variant='text' sx={buttonStyle}>
              <Typography
                color='var(--neutral-300, #9A9AA2)'
                sx={{ fontSize: '14px', fontWeight: 'medium' }}
              >
                Home
              </Typography>
            </Button>
          </Link>
        </Stack>
        <FormControl variant='standard' sx={{ height: '25px' }}>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            defaultValue={'Global (English)'}
            renderValue={(value) => {
              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <LanguageIcon sx={{ color: '#B3B3B9' }} />
                  {value}
                </Box>
              );
            }}
            sx={{
              color: '#B3B3B9',
              '& .MuiSelect-icon': { color: '#B3B3B9' },
              '&.MuiInput-underline:before': { border: 'none' },
            }}
          >
            <MenuItem value='Global (English)'>Global (English)</MenuItem>
            <MenuItem value='Español'>Español</MenuItem>
            <MenuItem value='Français'>Français</MenuItem>
            <MenuItem value='Português (Brasil)'>Português (Brasil)</MenuItem>
            <MenuItem value='Deutsch'>Deutsch</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Box>
  );
}
