import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

import { NewServiceType } from '../pages/Detail';

interface Props {
  service: NewServiceType;
}

function ServiceInfo({ service }: Props) {
  return (
    <Grid
      container
      spacing={6}
      sx={{
        marginTop: '40px',
        marginRight: '32px',
        justifyContent: 'space-between',
      }}
    >
      <Grid item lg={8} xl={8}>
        <Typography
          variant='h5'
          sx={{
            textAlign: 'start',
            fontWeight: 'bolder',
            marginBottom: '16px',
            fontSize: '24px',
          }}
        >
          {service.subtitle}
        </Typography>
        <Box
          color='text.secondary'
          sx={{
            textAlign: 'start',
            fontSize: '16px',
            letterSpacing: '0.256px',
            marginBottom: '40px',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              color: 'var(--neutral-800, #232326)',
              textAlign: 'start',
              marginBottom: '16px',
              fontSize: '24px',
              fontWeight: 'bolder',
            }}
          >
            Offerings
          </Typography>
          {service.offerings.map((offering, index) => (
            <Typography
              key={index}
              sx={{
                color: 'var(--neutral-600, #525259)',
                fontSize: '16px',
              }}
            >
              •{offering.description}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            textAlign: 'start',
            letterSpacing: '0.256px',
          }}
        >
          <Typography
            variant='h5'
            sx={{
              color: 'var(--neutral-800, #232326)',
              textAlign: 'start',
              marginBottom: '16px',
              fontSize: '24px',
              fontWeight: 'bolder',
            }}
          >
            Mastercard Solutions
          </Typography>
          {service.solutions.map((solution, index) => (
            <Typography
              key={index}
              sx={{
                color: 'var(--neutral-600, #525259)',
                fontSize: '16px',
                marginBottom: '28px',
              }}
            >
              <strong>•{solution.title}</strong>
              {solution.description && <span>: {solution.description} </span>}
              {solution.url && (
                <span>
                  Click{' '}
                  <a
                    href={solution.url}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      color: 'var(--third-400, #FF5F00)',
                      textDecoration: 'underline',
                    }}
                  >
                    here
                  </a>{' '}
                  to learn more.
                </span>
              )}
            </Typography>
          ))}
        </Box>
      </Grid>
      <Grid item lg={4} xl={3}>
        <Typography
          color='var(--neutral-500, #696972)'
          sx={{ fontWeight: '700', fontSize: '16px' }}
        >
          REGIONAL AVAILABILITY
        </Typography>
        {service.regions.map((region, index) => (
          <Typography
            key={index}
            sx={{
              color: 'var(--neutral-500, #696972)',
              fontSize: '16px',
            }}
          >
            •{region}
          </Typography>
        ))}
        <Typography
          sx={{
            color: 'var(--neutral-500, #696972)',
            fontSize: '16px',
            marginTop: '40px',
          }}
        >
          For specific market and customer type availability, please speak with
          a Mastercard representative
          {service.id === '4' && (
            <>
              <br />
              *Mastercard Networks Exchange is only available in these regions
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ServiceInfo;
