import { Box } from '@mui/material';
import React, { useRef, useEffect, useState, VideoHTMLAttributes } from 'react';
import ReactPlayer from 'react-player/lazy';

interface VideoPlayerProps extends VideoHTMLAttributes<HTMLVideoElement> {
  url: string;
  volume: number;
  playing: boolean;
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const { url, volume, playing } = props;
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setIsPlaying(playing);
  }, [playing]);

  useEffect(() => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.volume = volume;
  }, [volume]);

  return (
    <Box
      sx={{
        width: '90%',
        height: '90%',
        maxHeight: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactPlayer
        url={url}
        playing={isPlaying}
        volume={volume}
        controls
        width='100%'
        height='90%'
        style={{ maxHeight: '100vh' }}
      />
    </Box>
  );
};

export default VideoPlayer;
