import React, { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import API from '../assets/API.svg';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

const MainContent: React.FC<{
  onGoToOtherSection: () => void;
  onClose: () => void;
  onGoToThirdSection: () => void;
  cards: { title: string; content: string; content2: string }[];
}> = ({ onGoToOtherSection, onClose, onGoToThirdSection, cards }) => {
  const [selectedCards, setSelectedCards] = useState(new Set<number>());

  const handleCardClick = (index: number) => {
    const updatedSelection = new Set(selectedCards);

    if (updatedSelection.has(index)) {
      updatedSelection.delete(index);
    } else {
      updatedSelection.add(index);
    }

    setSelectedCards(updatedSelection);
  };

  return (
    <Box
      sx={{
        background: '#FFF',
        borderRadius: '8px',
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '40px 8px',
        marginY: '40px',
        marginX: '24px',
        width: '456px',
        maxHeight: '90vh',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ padding: '0px 24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={{ display: 'flex', width: '392px' }}>
            <Typography color="text.secondary" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
              Add this service to a bundle
            </Typography>
            <IconButton
              onClick={onClose}
              sx={{
                border: '1px solid var(--neutral-700, #3B3B3F)',
                borderRadius: '4px',
                background: 'var(--White, #FFF)',
                width: '40px',
                height: '40px',
                marginLeft: 'auto',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            id="outlined-basic"
            label="Filter bundle"
            variant="outlined"
            sx={{ width: '392px', backgroundColor: 'white', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '392px',
              gap: '8px',
              maxHeight: 'auto',
              overflowY: 'auto',
            }}
          >
            {cards.map((card, index: number) => (
              <Card
                key={index}
                onClick={() => handleCardClick(index)}
                sx={{
                  border: `1px solid ${
                    selectedCards.has(index) ? 'var(--primary-400, #EB001B)' : 'var(--neutral-100, #CDCDD0)'
                  }`,
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <Stack sx={{ width: '32px', height: '32px', marginRight: '8px' }}>
                    <img src={API} alt="api" />
                  </Stack>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{card.title}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{card.content}</Typography>
                    <Typography sx={{ fontSize: '13px' }}>{card.content2}</Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
          <Divider sx={{ width: '392px', background: 'var(--neutral-100, #CDCDD0)' }} />

          <Box sx={{ display: 'flex' }}>
            <Button
              variant="outlined"
              onClick={onGoToOtherSection}
              sx={{
                padding: '16px 24px',
                textTransform: 'none',
                color: 'text.primary',
                border: '1px solid var(--neutral-700, #3B3B3F)',
                fontWeight: 'bold',
              }}
            >
              Create new bundle
            </Button>
            <Button
              variant="contained"
              onClick={onGoToThirdSection}
              sx={{
                marginLeft: 'auto',
                textTransform: 'none',
                padding: '16px 24px',
                border: '1px solid var(--neutral-700, #3B3B3F)',
                fontWeight: 'bold',
                background: 'var(--neutral-800, #232326)',
              }}
            >
              Add to bundle
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const OtherSectionContent: React.FC<{
  onGoBack: () => void;
  onClose: () => void;
  onGoBackMain: (bundleName: string) => void;
}> = ({ onGoBack, onClose, onGoBackMain }) => {
  const [bundleName, setBundleName] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setBundleName(value);
    if (value.trim() === '') {
      setError('Bundle name cannot be empty');
    } else {
      setError('');
    }
  };

  const handleGoBackMain = () => {
    if (bundleName.trim() !== '') {
      onGoBackMain(bundleName);
    }
  };

  const isButtonEnabled = bundleName.trim() !== '' && !error;

  return (
    <Box
      sx={{
        background: '#FFF',
        borderRadius: '8px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '40px 8px',
        marginY: '40px',
        marginX: '24px',
        width: '456px',
      }}
    >
      <Box sx={{ padding: '0px 24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={{ display: 'flex', width: '392px' }}>
            <Typography color="text.secondary" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
              Create new bundle
            </Typography>
            <IconButton
              onClick={onClose}
              sx={{
                border: '1px solid var(--neutral-700, #3B3B3F)',
                borderRadius: '4px',
                background: 'var(--White, #FFF)',
                width: '40px',
                height: '40px',
                marginLeft: 'auto',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography color="text.secondary" sx={{ fontSize: '14', fontWeight: 'bold' }}>
            Bundle name
          </Typography>
          <TextField
            id="outlined-basic"
            label="ie. Your bundle name"
            variant="outlined"
            sx={{
              width: '392px',
              backgroundColor: 'white',
              marginBottom: '24px',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset',
            }}
            value={bundleName}
            onChange={handleInputChange}
            error={Boolean(error)}
            helperText={error}
          />

          <Typography color="text.secondary" sx={{ fontSize: '14', fontWeight: 'bold' }}>
            Description (Optional)
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            multiline
            rows={4}
            sx={{ width: '392px', backgroundColor: 'white', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset' }}
          />
          <Divider sx={{ width: '392px', background: 'var(--neutral-100, #CDCDD0)' }} />

          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Button
              variant="contained"
              disabled={!isButtonEnabled}
              onClick={handleGoBackMain}
              sx={{
                padding: '16px 24px',
                textTransform: 'none',
                border: '1px solid var(--neutral-700, #3B3B3F)',
                fontWeight: 'bold',
                background: 'var(--neutral-800, #232326)',
              }}
            >
              Create new bundle
            </Button>
            <Button
              variant="outlined"
              onClick={onGoBack}
              sx={{
                textTransform: 'none',
                padding: '16px 24px',
                color: 'text.primary',
                border: '1px solid var(--neutral-700, #3B3B3F)',
                fontWeight: 'bold',
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ThirdSectionContent: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box
      sx={{
        background: '#FFF',
        borderRadius: '8px',
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '808px',
        height: '64px',
        padding: ' 16px 16px',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CheckCircleOutlineIcon sx={{ color: '#76A22B', fontSize: '32px', width: '32px', height: '32px' }} />
        <Typography color="text.primary" sx={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '32px' }}>
          Succesfully added to “New bundle name”
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            width: '32px',
            height: '32px',
            marginLeft: 'auto',
          }}
        >
          <CloseIcon sx={{ fontSize: '32px' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

const ModalContent: React.FC<ModalProps> = ({ open, handleClose }) => {
  const [showOtherSection, setShowOtherSection] = useState(false);
  const [showThirdSection, setShowThirdSection] = useState(false);
  const [cards, setCards] = useState([
    { title: 'Authentication items', content: '4 services', content2: 'Updated 3 days ago' },
    { title: 'Mobile services', content: '4 services', content2: 'Updated 3 days ago' },
    { title: 'Banking app resources', content: '4 services', content2: 'Updated 3 days ago' },
  ]);

  const handleGoToOtherSection = () => {
    setShowOtherSection(true);
  };

  const handleGoToThirdSection = () => {
    setShowOtherSection(false);
    setShowThirdSection(true);
  };

  const handleGoBackMain = (bundleName: string) => {
    setCards([...cards, { title: bundleName, content: '4 services', content2: 'Updated 3 days ago' }]);
    setShowOtherSection(false);
  };

  const handleGoBack = () => {
    setShowOtherSection(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ padding: '0px 24px' }}>
        {showOtherSection ? (
          <OtherSectionContent onGoBack={handleGoBack} onGoBackMain={handleGoBackMain} onClose={handleClose} />
        ) : showThirdSection ? (
          <ThirdSectionContent onClose={handleClose} />
        ) : (
          <MainContent
            onGoToOtherSection={handleGoToOtherSection}
            onGoToThirdSection={handleGoToThirdSection}
            onClose={handleClose}
            cards={cards}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ModalContent;
