import React from 'react';
import SliderCard from './SliderCard';
import { Grid } from '@mui/material';

const sliderData = require('../data/sliderCardsInfo.json');

export default function Hero() {
  return (
    <>
      <Grid container spacing={4} sx={{ marginBottom: '48px' }}>
        {sliderData.map((sliderCard: any, sliderCardIndex: number) => (
          <SliderCard
            key={sliderCardIndex}
            caption={sliderCard.caption}
            title={sliderCard.title}
            description={sliderCard.description}
            image={sliderCard.image}
            color={sliderCard.color}
          />
        ))}
      </Grid>
    </>
  );
}
