import { Typography, Box, Button } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface topChartElement {
  position: number;
  title: string;
  description: string;
  url: string;
}

export default function SolutionCard(props: topChartElement) {
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
        <Typography
          sx={{
            color: 'var(--neutral-800, #232326)',
            textAlign: 'center',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 800,
            lineHeight: '24px',
            letterSpacing: '0.208px',
          }}
        >
          {props.position}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              marginBottom: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              gap: '8px',
              alignSelf: 'stretch',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <RouterLink to={props.url} style={{ textDecoration: 'none' }}>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Typography
                  sx={{
                    color: 'var(--neutral-800, #232326)',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '24px',
                    letterSpacing: '0.24px',
                  }}
                >
                  {props.title}
                </Typography>
              </Box>
            </RouterLink>
          </Box>
          <RouterLink to={props.url} style={{ textDecoration: 'none' }}>
            <Typography
              sx={{
                marginBottom: '16px',
                color: 'var(--neutral-400, #81818B)',
                fontSize: '16px',
                fontStyle: 'normal',
                width: '85%',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.224px',
              }}
            >
              {props.description}
            </Typography>
          </RouterLink>
          <RouterLink to={props.url} style={{ textDecoration: 'none' }}>
            <Button
              variant='contained'
              size='small'
              sx={{
                backgroundColor: 'var(--neutral-700, #3B3B3F)',
                color: 'var(--neutral-0, #FFFFFF)',
                fontSize: '0.6rem',
                px: '20px',
                py: '5px',
                fontWeight: 700,
              }}
            >
              View More
            </Button>
          </RouterLink>
        </Box>
      </Box>
    </>
  );
}
