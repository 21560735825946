import React from 'react';
import { Box, Typography, Stack, Button, ListItemIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icon from '../assets/iconCard.svg';
import Logo from '../assets/logoMaster.svg';
import ListIcon from '@mui/icons-material/FormatListNumbered';
import Header from './Header';
import bluredLogo from '../assets/bluredLogo-v2.png';
import techBg from '../assets/tech-bg.png';
import ServiceInfo from './ServiceInfo';
import { NewServiceType } from '@/pages/Detail';

interface Props {
  id: string;
}

const newServices: NewServiceType[] = require('../data/newService.json');

function ServiceDetail({ id }: Props) {
  const service = newServices.find((service) => service.id === id);
  const navigate = useNavigate();

  if (!service) {
    navigate('/detail');
    return;
  }

  const Back = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          backgroundImage: `url(${bluredLogo})`,
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          src={techBg}
          alt=''
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: '0 auto',
            width: '50%',
            maxHeight: '260px',
            objectFit: 'cover',
            zIndex: -1,
          }}
        />
        <Header />
        <Box
          sx={{
            display: 'flex',
            margin: '0 136px 0',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Button
              variant='text'
              sx={{
                alignItems: 'start',
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: 'Mark pro',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={Back}
            >
              ← Back
            </Button>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}
          >
            <Stack sx={{ width: '24px', height: '24px', marginRight: '16px' }}>
              <img src={Icon} alt='icon' />
            </Stack>
            <Typography
              variant='h4'
              component='div'
              fontWeight='bold'
              sx={{
                fontSize: '32px',
                lineHeight: '48px',
                letterSpacing: '0.512px',
              }}
            >
              {service.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '40px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Stack sx={{ width: '40px' }}>
                <img src={Logo} alt='logo' />
              </Stack>
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ fontWeight: 'medium' }}
              >
                Developed by
              </Typography>
              <Typography
                variant='body2'
                color='text.primary'
                sx={{ marginRight: '32px', fontWeight: 'bold' }}
              >
                Mastercard®
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <ListItemIcon sx={{ marginRight: '-32px' }}>
                  <ListIcon />
                </ListItemIcon>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ fontWeight: 'bold' }}
                >
                  Version
                </Typography>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ fontWeight: '800' }}
                >
                  3.12
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'var(--neutral-100, #FFFFFF)',
                borderRadius: '8px 8px 0 0',
                padding: '8px 16px',
              }}
            >
              <Typography
                sx={{
                  color: 'var(--third-400, #FF5F00)',
                  fontWeight: 'bold',
                }}
              >
                Overview
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Description */}

      <Box
        sx={{
          display: 'flex',
          margin: '0 136px 40px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ServiceInfo service={service} />
      </Box>
    </Box>
  );
}

export default ServiceDetail;
