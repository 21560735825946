import React, { useState } from 'react';
import { Button } from '@mui/material';
import ModalContent from './ModalContent';

const ModalBundle: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant='contained'
        onClick={handleOpen}
        sx={{
          border: '1px solid var(--secondary-700, #845105)',
          background: 'var(--third-400, #FF5F00)',
          borderRadius: '4px',
          fontFamily: 'Mark pro',
          fontSize: '12px',
          fontWeight: '700',
          lineHeight: '24px',
          letterSpacing: '0.224px',
          textTransform: 'none',
        }}
      >
        Add to my bundle
      </Button>
      <ModalContent open={open} handleClose={handleClose} />
    </div>
  );
};

export default ModalBundle;
