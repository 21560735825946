import React, { useContext } from 'react';
import {
  Tabs,
  Tab,
  Box,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Divider,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import bluredLogo from '../assets/bluredLogo.png';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from '../context/SearchContext';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabSearch() {
  const navigate = useNavigate();

  const Back = () => {
    navigate(-1);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const context = useContext(SearchContext);

  if (!context) {
    return null;
  }

  const { searchValue } = context;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <img
        alt=""
        src={bluredLogo}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          zIndex: -1,
          transform: 'scaleX(-1)',
        }}
      />
      <Box sx={{ display: 'flex' }}>
        <Button
          variant="text"
          sx={{
            alignItems: 'start',
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: 'Mark pro',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={Back}
        >
          ← Back
        </Button>
      </Box>
      <TextField
        id="outlined-basic"
        label="Value-add"
        variant="outlined"
        sx={{
          width: '687px',
          marginTop: '16px',
          height: '64px',
          border: 'var (--neutral-300, #9A9AA2)',
          borderRadius: '8px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Close />
              <Typography color="text.secondary" sx={{ alignItems: 'center', margin: '16px' }}>
                |
              </Typography>
              <Search />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Stack>
        <Typography
          sx={{
            color: 'var(--neutral-800, #232326)',
            textAlign: 'start',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '48px',
            marginBottom: '16px',
            marginTop: '32px',
          }}
        >
          Search results
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: 'var(--neutral-800, #232326)',
            textAlign: 'start',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            width: '687px',
            lineHeight: '24px',
            marginTop: '24px',
            marginBottom: '48px',
          }}
        >
          Displaying <strong>7</strong> results for <strong>“{searchValue}”</strong>
        </Typography>
      </Stack>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="primary"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            label="All"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 0 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Issuer"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 1 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Acquirer"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 2 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="PSP"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 3 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(3)}
          />
          <Tab
            label="ACH"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 4 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(4)}
          />
        </Tabs>
        <FormControl>
          <InputLabel id="demo-simple-select-label" sx={{ color: 'var(--neutral-400, #81818B)', fontWeight: 'bold' }}>
            Category
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Category"
            defaultValue={'all'}
            sx={{ width: '200px', color: 'var(--neutral-400, #81818B)' }}
          >
            <MenuItem value="all">Please select</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default TabSearch;
