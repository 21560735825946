import React from 'react';

import { styled } from '@mui/material/styles';

import Page from '../components/Page';
import Header from '../components/Header';
import CardList from '../components/CardList';
import TabSearch from '../components/TabSearch';
import Pagination from '@mui/material/Pagination';
import cardInfo from '../data/cardInfo.json';
import Footer from '../components/Footer';

const ContentStyle = styled('div')({
  display: 'flex',
  margin: '0 136px',
  flexDirection: 'column',
  justifyContent: 'center',
});

export default function Search() {
  return (
    <Page title="Search">
      <Header />
      <ContentStyle>
        <TabSearch />
        <CardList cards={cardInfo} />
      </ContentStyle>
      <Pagination
        count={10}
        shape="rounded"
        sx={{
          color: 'var(--neutral-600, #525259)',
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '24px',
          letterSpacing: '0.24px',
          justifyContent: 'center',
          display: 'flex',
          marginBottom: '72px',
        }}
      />
      <Footer />
    </Page>
  );
}
