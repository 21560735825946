import React, { useEffect } from 'react';

import Page from '../components/Page';
import Footer from '../components/Footer';
import ServiceDetail from '../components/ServiceDetail';
import { useNavigate, useParams } from 'react-router-dom';
import TabDetail from '../components/TabDetail';
import { useLocation } from 'react-router-dom';

export interface NewServiceType {
  id: string;
  name: string;
  subtitle: string;
  offerings: {
    description: string;
  }[];
  solutions: {
    title: string;
    description?: string;
    url?: string;
  }[];
  regions: string[];
}

const newServices: NewServiceType[] = require('../data/newService.json');

export default function Detail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isExist = newServices.find((service) => service.id === id);

  useEffect(() => {
    if (!isExist) {
      navigate('/detail');
    }
  }, [isExist, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Page title='Details'>
      {isExist ? <ServiceDetail id={isExist.id} /> : <TabDetail />}
      <Footer />
    </Page>
  );
}
