// Archivo: SearchContext.tsx
import React, { createContext, useState, ReactNode } from 'react';

interface SearchContextProps {
  children: ReactNode;
}

interface SearchContextValue {
  searchValue: string;
  setSearch: (value: string) => void;
}

const SearchContext = createContext<SearchContextValue | undefined>(undefined);

const SearchProvider: React.FC<SearchContextProps> = ({ children }) => {
  const [searchValue, setSearchValue] = useState('');

  const setSearch: SearchContextValue['setSearch'] = (value) => {
    setSearchValue(value);
  };

  return <SearchContext.Provider value={{ searchValue, setSearch }}>{children}</SearchContext.Provider>;
};

export { SearchProvider, SearchContext };
