import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from '../assets/Icon.svg';
import logoText from '../assets/logoText.svg';

//styles
import '../styles/landingPage.css';

export default function Hero() {
  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ margin: '24px' }}
      >
        <Stack direction={'row'} spacing={'12px'}>
          <Link to='/'>
            <img src={Icon} alt='' style={{ width: '50px', height: '50px' }} />
          </Link>
          <Link to='/'>
            <img
              src={logoText}
              alt=''
              style={{ width: '144px', height: '50px' }}
            />
          </Link>
        </Stack>
        <Stack
          direction={'row'}
          spacing={'32px'}
          sx={{ alignItems: 'center' }}
        ></Stack>
      </Stack>
    </>
  );
}
