import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import homeHero1 from '../assets/HomeHero1.png';
import homeHero2 from '../assets/HomeHero2.png';
import homeHero3 from '../assets/HomeHero3.png';

const imageArray: string[] = [homeHero1, homeHero2, homeHero3];

interface SliderCardProps {
  caption: string;
  title: string;
  description: string;
  image: string;
  color: string;
}

export default function SliderCard(props: SliderCardProps) {
  const image = props.image;
  const imageIndex = imageArray.findIndex((element) => element.includes(image));
  return (
    <Grid item xs={4}>
      <Box
        sx={{
          backgroundColor: props.color,
          borderRadius: 8,
          padding: '32px',
          paddingRight: 0,
          paddingBottom: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
        }}
      >
        <Box sx={{ width: '50%' }}>
          <Typography
            variant={'body1'}
            sx={{
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '0.208px',
              color: '#FCD8A4',
            }}
          >
            {props.caption}
          </Typography>
          <Typography
            sx={{
              color: 'var(--White, #FFF)',
              fontSize: '19px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              letterSpacing: '0.32px',
              mb: '16px',
            }}
          >
            {props.title}
          </Typography>
          <Typography
            sx={{
              color: 'var(--secondary-50, #FDECD1)',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.256px',
              paddingBottom: '16px',
            }}
          >
            {props.description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            marginRight: '10px',
          }}
        >
          <img
            src={imageArray[imageIndex]}
            alt=''
            style={{
              width: '100%',
              paddingLeft: '12px',
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
}
