import React, { useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';
import cardPayment1 from '../assets/HomeHero1.png';
import HomeSlider from './HomeSlider';
import bluredLogo from '../assets/bluredLogo.png';
import ModalVideo from './ModalVideo';
import VideoPlayer from './VideoPlayer';
import mastercardVideo from '../assets/mastercard_access.mp4';

export default function Hero() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loadVideo, setLoadVideo] = useState<boolean>(false);

  const video = mastercardVideo;

  return (
    <>
      <img
        alt='background logo image'
        src={bluredLogo}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          zIndex: -1,
          transform: 'scaleX(-1)',
        }}
      />
      <Box
        sx={{
          height: 288,
          flexShrink: 0,
          backgroundColor: '#AA3F00',
          borderRadius: 8,
          paddingLeft: '120px',
          marginBottom: '32px',
        }}
      >
        <Stack direction={'row'}>
          <Stack sx={{ marginTop: '48px' }}>
            <Typography
              variant={'body1'}
              sx={{
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.208px',
                color: '#FCD8A4',
              }}
            >
              Welcome to Mastercard Access
            </Typography>
            <Typography
              variant={'h3'}
              sx={{
                fontSize: '32px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '48px',
                letterSpacing: '0.512px',
                color: '#FFFFFF',
              }}
            >
              Take advantage of our intelligent, trusted suite of services
              designed to accelerate innovation
            </Typography>
            <Button
              variant={'contained'}
              sx={{
                display: 'inline-flex',
                height: '40px',
                width: '220px',
                padding: '8px 24px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                flexShrink: 0,
                backgroundColor: '#DD8608',
                marginTop: '24px',
              }}
              onClick={() => {
                setIsModalVisible(true);
                setLoadVideo(true);
              }}
            >
              <Typography
                sx={{
                  textTransform: 'none',
                  textShadow: '0px 1px 1px rgba(44, 44, 44, 0.80)',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '0.224px',
                }}
              >
                Learn more
              </Typography>
            </Button>
          </Stack>
          <img src={cardPayment1} alt='cardPayment1' />
        </Stack>
      </Box>
      <HomeSlider />
      <ModalVideo
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100vw',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              position: 'absolute',
              top: '5px',
              left: '5px',
              color: '#FFFFFF',
              fontSize: '24px',
              cursor: 'pointer',
              zIndex: 1,
              '&:hover': {
                transform: 'scale(1.1)',
                opacity: 0.9,
              },
            }}
            onClick={() => setIsModalVisible(false)}
          >
            X
          </Button>
          {(loadVideo || isModalVisible) && (
            <VideoPlayer
              url={video}
              width='100%'
              height='auto'
              controls
              volume={0.5}
              style={{ maxHeight: '100vh' }}
              playing={isModalVisible}
              onEnded={() => setIsModalVisible(false)}
            />
          )}
        </Box>
      </ModalVideo>
    </>
  );
}
