import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SolutionCard from './SolutionsCard';

interface solutionsType {
  position: number;
  title: string;
  description: string;
  url: string;
}

const solutions: solutionsType[] = require('../data/solutions.json');

export default function HomeSolutions() {
  return (
    <Box sx={{ width: '70%', mx: 'auto', paddingBottom: '100px' }}>
      <Stack spacing={'8px'} sx={{ marginBottom: '32px' }}>
        <Typography
          sx={{
            color: 'var(--neutral-800, #232326)',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '32px',
            letterSpacing: '0.384px',
          }}
        >
          Solutions Available
        </Typography>
        <Typography
          sx={{
            color: 'var(--neutral-500, #696972)',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.256px',
          }}
        >
          Discover all the solutions that you can leverage via Mastercard Access
        </Typography>
      </Stack>
      <Grid container rowSpacing={4} columnSpacing={10}>
        {solutions.map((solutionItem: solutionsType, index: number) => (
          <Grid item xs={6} key={index} direction={'row'} spacing={'auto'}>
            <SolutionCard
              position={solutionItem.position}
              title={solutionItem.title}
              description={solutionItem.description}
              url={solutionItem.url}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
