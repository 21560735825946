import React from 'react';
import Page from '../components/Page';
import Header from '../components/Header';
import HomeHero from '../components/HomeHero';
import Footer from '../components/Footer';
import { Stack } from '@mui/material';
import HomeSolutions from '../components/HomeSolutions';

export default function Home() {
  return (
    <Page title='Landing Page'>
      <Header />
      <Stack sx={{ marginX: '24px', marginTop: '62px', position: 'relative' }}>
        <HomeHero />
        <HomeSolutions />
      </Stack>
      <Footer />
    </Page>
  );
}
