import React from 'react';
import { Box, Card, CardContent, Typography, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Icon from '../assets/iconCard.svg';
import Logo from '../assets/logoMaster.svg';
import ModalCard from './ModalCard';
import Tag from './Tag';

interface TagData {
  text: string;
  backgroundColor: string;
}

interface CardData {
  title: string;
  subtitle: string;
  content: string;
  tags: TagData[];
}

interface CardListProps {
  cards: CardData[];
}

const CardList: React.FC<CardListProps> = ({ cards }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-between',
        padding: '24px',
      }}
    >
      {cards.slice(3, 13).map((card, index) => (
        <Card
          key={index}
          sx={{
            flexBasis: 'calc(50% - 32px)',
            marginBottom: '40px',
            border: '1px solid var(--neutral-100, #CDCDD0);',
            borderRadius: '8px',
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <RouterLink to={'/detail'} style={{ textDecoration: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Stack sx={{ width: '32px', height: '32px', marginRight: '8px' }}>
                    <img src={Icon} alt="icon" />
                  </Stack>
                  <Typography
                    component="div"
                    fontWeight="bold"
                    sx={{
                      color: 'var(--neutral-800, #232326)',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '32px',
                      letterSpacing: '0.32px',
                    }}
                  >
                    {card.title}
                  </Typography>
                </Box>
              </RouterLink>
              <ModalCard />
            </Box>
            <RouterLink to={'/detail'} style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginBottom: '16px',
                }}
              >
                <Stack sx={{ width: '40px', marginRight: '8px' }}>
                  <img src={Logo} alt="logo" />
                </Stack>
                {card.tags.map((tag, tagIndex) => (
                  <Tag key={tagIndex} type="primary">
                    {tag.text}
                  </Tag>
                ))}
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                <Typography
                  color="text.secondary"
                  sx={{
                    overflow: 'hidden',
                    color: 'var(--neutral-400, #81818B)',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.224px',
                  }}
                >
                  {card.content}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    color="text.secondary"
                    sx={{
                      marginRight: '16px',
                      color: 'var(--neutral-400, #81818B)',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      letterSpacing: '0.208px',
                    }}
                  >
                    Developed by:
                  </Typography>
                  <Stack sx={{ width: '40px' }}>
                    <img src={Logo} alt="logo" />
                  </Stack>
                  <Typography
                    color="text.secondary"
                    sx={{
                      marginRight: '8px',
                      color: 'var(--neutral-600, #525259)',
                      fontSize: '13px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '24px',
                      letterSpacing: '0.208px',
                    }}
                  >
                    Mastercard®
                  </Typography>
                </Box>
              </Box>
            </RouterLink>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default CardList;
