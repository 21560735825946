import { Box } from '@mui/material';
import React, { useEffect } from 'react';

interface ModalVideoProps {
  isVisible: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const ModalVideo = ({ isVisible, children, onClose }: ModalVideoProps) => {
  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  const handleModalClose = (e: any) => {
    if (e.target.classList.contains('modal-video')) {
      onClose();
    }
  };

  return (
    <Box
      className='modal-video'
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 99,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        backdropFilter: 'blur(4px)',
        display: 'grid',
        placeItems: 'center',
      }}
      onClick={handleModalClose}
      style={{ display: isVisible ? 'grid' : 'none' }}
    >
      {children}
    </Box>
  );
};

export default ModalVideo;
