import React from 'react';
import { Tabs, Tab, Typography, Box, TextField, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Search from '@mui/icons-material/Search';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const tabTitleStyle = {
  color: 'var(--neutral-800, #232326)',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '0.384px',
  textTransform: 'none',
};

const tabDescriptionStyle = {
  color: 'var(--neutral-500, #696972)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px' /* 150% */,
  letterSpacing: '0.256px',
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();

    navigate('/search');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="primary"
          TabIndicatorProps={{ style: { display: 'none' } }}
          sx={{ marginLeft: '20px' }}
        >
          <Tab
            label="All"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 0 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Issuer"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 1 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Acquirer"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 2 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="PSP"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 3 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(3)}
          />
          <Tab
            label="ACH"
            sx={{
              color: 'var(--third-400, #FF5F00)',
              backgroundColor: value === 4 ? 'var(--third-50, #FFDFCC)' : 'transparent',
              borderRadius: '100px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
            {...a11yProps(4)}
          />
        </Tabs>
        <form onSubmit={handleSearch}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Search.."
            sx={{
              width: '328px',
              marginRight: '24px',
              border: 'var(--neutral-300, #9A9AA2)',
              boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.08) inset',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Box>

      <TabPanel value={value} index={0}>
        <Typography sx={tabTitleStyle}>All</Typography>
        <Typography color="text.secondary" sx={tabDescriptionStyle}>
          Discover all the products that Mastercard® Access has for you
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography sx={tabTitleStyle}>Issuer</Typography>
        <Typography color="text.secondary" sx={tabDescriptionStyle}>
          Discover all the products that Mastercard® Access has for you
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography sx={tabTitleStyle}>Acquirer</Typography>
        <Typography color="text.secondary" sx={tabDescriptionStyle}>
          Discover all the products that Mastercard® Access has for you
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography sx={tabTitleStyle}>PSP</Typography>
        <Typography color="text.secondary" sx={tabDescriptionStyle}>
          Discover all the products that Mastercard® Access has for you
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography sx={tabTitleStyle}>ACH</Typography>
        <Typography color="text.secondary" sx={tabDescriptionStyle}>
          Discover all the products that Mastercard® Access has for you
        </Typography>
      </TabPanel>
    </Box>
  );
}

export default BasicTabs;
