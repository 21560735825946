import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModalContent from './ModalContent';

const ModalBundle: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleOpen}
        sx={{
          border: '1px solid var(--secondary-500, #DD8608)',
          borderRadius: '4px',
          background: 'var(--White, #FFF)',
          width: '40px',
          height: '40px',
        }}
      >
        <AddIcon sx={{ color: '#B06C06', fontSize: '30px' }} />
      </IconButton>
      <ModalContent open={open} handleClose={handleClose} />
    </div>
  );
};

export default ModalBundle;
